import React from "react";
import Fade from 'react-reveal/Fade';


function Hero() {
    return (
      <header id="Home" className="Hero">
        <Fade>
          <div className="HeroText">Christina Serafina Healing</div>
          </Fade>
  </header>
      
    );
  }
  
  export default Hero;